/* Defaults */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.notSelectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
}

html {
    background-color: white;
    font-size: 10px;
    color: black;
    /* cursor: none; */
}

body {
    background-color: #ffffff;
    box-sizing: border-box;
    font-size: 10px;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* backdrop-filter: blur(10px); */
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.mainInteractions
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    outline: none;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 1;
}

.cursorFollower
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    outline: none;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 100000000000;
}

/* Nav */
.nav {
    position: fixed;
    width: 100vw;
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
}

/* Logo */
.leftSideNav {
    font-size: 3rem;
    cursor: pointer;
}

.leftLogo, .rightLogo {
    overflow: hidden;
}

.subProjectName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Antonio', sans-serif;
    font-size: 3.5rem;
    /* font-weight: bold; */
}

#greenLogo {
    color: #3d7c4a;
}

#orangeLogo {
    color: #f26b30;
}

#blueLogo {
    color: #292c56;
}

#circleLogo {
    padding-top: 0.4rem;
}

#triangleLogo {
    padding-top: 0.3rem;
}

#rectangleLogo {
    padding-top: 0.3rem;
}

.rightSideNav {
    font-size: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Hero Section */
.heroSection {
    position: fixed;
    z-index: 10000;
}

.contributeCTA {
    padding: 1rem;
}

.contributeCTAButton {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1.5rem;
    border: 1px solid #f26b30;
    color: #f26b30;
    font-family: 'Antonio', sans-serif;
    font-weight: bold;
    letter-spacing: 0.5px;
    height: 5rem;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    background-color: #ffffff;
}

.CTANormalText {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Menu Button */
.menu {
    padding: 1rem;
}

.menuButton {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 1px solid #3d7c4a;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.menuButtonBars {
    width: 100%;
    border: 1px solid #3d7c4a;
}

/* Main Hero */
.mainHeroSection {
    width: 100vw;
    height: 100vh;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.topMainHeroSection {
    width: 100%;
    /* border: 2px solid red; */
    padding-left: 20rem;
    padding-right: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSideMainHeroSection {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 10vh;
    /* border: 2px solid red; */
}

.threeShapes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
}

.threeShapesImages {
    transform: scale(0.4);
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.subHeroText {
    font-family: 'Antonio', sans-serif;
    font-size: 8rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

#topSubHeroText, #midSubHeroText {
    overflow: hidden;
}

#bottomSubHeroText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.orangeText {
    color: #f26b30;
    z-index: 10;
}

.inOnly {
    z-index: 20;
}

.heroDescriptionText {
    margin-top: 2rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 2.6rem;
    width: 35rem;
}

.bottomMainHeroSection {
    /* border: 2px solid red; */
    width: 100%;
}

.topBottomMainHeroSection {
    font-family: 'Antonio', sans-serif;
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomBottomMainHeroSection {
    margin-top: 2rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;
}

#bottomTriangleRight {
    margin-right: 0.8rem;
}

/* Right Side */
.rightSideMainHeroSection {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22.5vh;
    /* border: 2px solid red; */
}

#heroPicture1 {
    z-index: 1;
}

#heroPicture2 {
    margin-left: -8rem;
    margin-right: -14rem;
    z-index: 2;
    /* pointer-events: none; */
}

#heroPicture3 {
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;
}

#tree {
    z-index: 5;
}

/* Hero CTA */
#heroSectionCTA {
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    background-color: #f26b30;
    margin-left: -10rem;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Antonio', sans-serif;
    /* font-weight: bold; */
    font-size: 1.7rem;
    cursor: pointer;
}

.heroCTAText {
    width: 16rem;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

#whiteHeroCTAText {
    color: #ffffff;
}

#blackHeroCTAText {
    color: #000000;
    font-weight: bold;
}

.heroCTAUnderlines {
    display: flex;
    justify-content: center;
    align-items: center;
}

.underlineBar {
    width: 100%;
    border: 1px solid #000000;
}

#invisibleBar {
    opacity: 0;
}

.hoverCTA {
    border: 4px dotted #f26b30;
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    border-style: dashed;
    margin-left: -20rem;
    z-index: 20000;
    pointer-events: none;
}

/* Loading Page */
.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 10000000000000000000000000;
    pointer-events: none;
}